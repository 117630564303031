














import { Component, Prop, Vue } from "vue-property-decorator";
import { IPermissionTreeNode } from "@/views/admin/role/permissionGrant/permissionTree";

@Component({
  name: "FunctionGrant",
})
export default class FunctionGrant extends Vue {
  @Prop({ required: true })
  node!: IPermissionTreeNode;

  private _functions?: IPermissionTreeNode[] = undefined;

  get functions() {
    if (!this._functions) {
      if (this.node.children) {
        this._functions = this.node.children.filter(
          (item) => item.data.permission.isHiddenForMenu === true
        );
      } else {
        this._functions = [];
      }
    }
    return this._functions;
  }

  onFunctionGrantChange(newValue: boolean, node: IPermissionTreeNode) {
    if (newValue) {
      this.node.checked = true;
      let parentNode = this.node.parent;
      while (parentNode) {
        parentNode.checked = true;
        parentNode = parentNode.parent;
      }
    } else {
      const hasGrantSome = this.functions.filter((s) => s.checked).length > 0;
      if (!hasGrantSome) {
        this.node.checked = false;
      }
    }
  }
}
