import {
  DataPermissionScopeType,
  DataPermissionSettingDto,
  PermissionDefinitionDto,
} from "@/api/appService";

export interface PermissionGrant {
  permission: PermissionDefinitionDto;
  dataLimit?: {
    scope: DataPermissionScopeType;
    details?: string[];
    permissionCode: string;
  };
}

export interface IPermissionTreeNode {
  data: PermissionGrant;
  checked: boolean;
  id: any;
  label: string;
  children?: IPermissionTreeNode[];
  parent?: IPermissionTreeNode;
}

export function createPermissionGrantTree(
  grantedDataPermissions: DataPermissionSettingDto[],
  items: PermissionDefinitionDto[],
  parentId: number | null,
  parentNode?: IPermissionTreeNode
): IPermissionTreeNode[] {
  const result = items
    .filter((item) => item.parentId === parentId)
    .map((definition) => {
      // get dataPermissionSetting of current permissionDefinition
      const dataPermissionSettingFilterResult = grantedDataPermissions.filter(
        (item) => item.permissionCode === definition.name
      );
      const dataPermissionSetting: DataPermissionSettingDto | undefined =
        dataPermissionSettingFilterResult.length
          ? dataPermissionSettingFilterResult[0]
          : undefined;

      // build node
      const node = {
        id: definition.id,
        checked: false,
        label: definition.displayName,
        data: {
          permission: definition,
        },
        parent: parentNode,
      } as IPermissionTreeNode;

      // configuration data permission
      if (definition.enableDataPermission) {
        node.data.dataLimit = {
          permissionCode: definition.name!,
          scope: DataPermissionScopeType.All,
          details: dataPermissionSetting?.details ?? [],
        };

        if (!dataPermissionSetting) {
          // if has not grant data permission , the scope default value is All or Mine
          node.data.dataLimit.scope = definition.enableDataPermissionForCreator
            ? DataPermissionScopeType.Mine
            : DataPermissionScopeType.All;
        } else {
          node.data.dataLimit.scope = dataPermissionSetting.scope!;
          // if permissionDefinition disable dataPermission-specifiedLimit, and dataPermission.scope old value is specified
          // then set scope to Mine
          if (
            !definition.enableDataPermissionSpecifiedLimit &&
            dataPermissionSetting.scope === DataPermissionScopeType.Specified
          ) {
            node.data.dataLimit.scope = DataPermissionScopeType.Mine;
          }

          // if permissionDefinition disable dataPermissionLimitByCreator,
          // then set is scope to all
          if (
            !definition.enableDataPermissionForCreator &&
            [
              DataPermissionScopeType.Mine,
              DataPermissionScopeType.MyDepartment,
              DataPermissionScopeType.MyDepartmentAndSubs,
            ].includes(dataPermissionSetting.scope!)
          ) {
            node.data.dataLimit.scope = DataPermissionScopeType.All;
          }
        }
      }

      node.children = createPermissionGrantTree(
        grantedDataPermissions,
        items,
        definition.id!,
        node
      );

      return node;
    });

  return result;
}

export function getGrantedPermissions(
  nodes: IPermissionTreeNode[] | undefined
): string[] {
  let result: string[] = [];

  if (nodes && nodes.length) {
    nodes.forEach((permissionItem) => {
      if (permissionItem.checked) {
        result.push(permissionItem.data.permission.name!);
      }
      result = [...result, ...getGrantedPermissions(permissionItem.children)];
    });
  }

  return result;
}

export function getGrantedDataPermissions(
  nodes: IPermissionTreeNode[] | undefined
): DataPermissionSettingDto[] {
  let result: DataPermissionSettingDto[] = [];
  if (nodes && nodes.length) {
    nodes.forEach((permissionItem) => {
      if (permissionItem.checked) {
        console.log(
          "getGrantedDataPermissions:",
          permissionItem.label,
          permissionItem.data.dataLimit
        );
        if (permissionItem.data.dataLimit) {
          result.push({
            permissionCode: permissionItem.data.dataLimit.permissionCode,
            scope: permissionItem.data.dataLimit.scope,
            details: permissionItem.data.dataLimit.details,
          });
        }
        result = [
          ...result,
          ...getGrantedDataPermissions(permissionItem.children),
        ];
      }
    });
  }
  return result;
}
