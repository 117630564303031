







import {
  Component,
  Model,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import { createNgTree, createTree, INgNode } from "@/utils/tree";
import { CmsCategoryDto } from "@/api/appService";
import api from "@/api/index";
import { ElTree } from "element-ui/types/tree";

@Component({
  name: "DataPermissionDataSourceCmsCategories",
})
export default class DataPermissionDataSourceCmsCategories extends Vue {
  @Model("change", { required: false, default: [] })
  grantValues!: string[];

  values: string[] = [];

  treeData: INgNode<CmsCategoryDto>[] = [];

  defaultProps = {
    label: "label",
    children: "children",
  };

  @Watch("values", { deep: true })
  onValuesChange(newValue: string[]) {
    this.$emit("change", newValue);
  }

  created() {
    this.values = this.grantValues;

    api.cmsCategory.getAll({ maxResultCount: 65535 }).then((res) => {
      this.treeData = createTree<CmsCategoryDto>({
        disabled: false,
        labelProperty: "displayName",
        idProperty: "id",
        keyProperty: "id",
        array: res.items ?? [],
        childrenProperty: "children",
        fieldMappings: () => {
          return "";
        },
        parentIdProperty: "parentId",
        parentNode: undefined,
        parentIdValue: null,
      });

      this.$nextTick(() => {
        (this.$refs.cmsCategoryTree as any).setCheckedKeys(this.values);
      });
    });
  }

  onCmsCategoryCheckChange(
    data: INgNode<CmsCategoryDto>,
    isChecked: boolean,
    isSubTreeHasChecked: boolean
  ) {
    const checkedCategories = (
      this.$refs.cmsCategoryTree as any
    ).getCheckedKeys();
    this.$emit("change", checkedCategories);
  }
}
