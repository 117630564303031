















































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import { RoleDto, RoleDtoPagedResultDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";

@Component({
  name: "RoleList",
  components: {
    PagedTableView,
  },
})
export default class RoleList extends Vue {
  queryForm = {
    keyword: "",
  };

  // 获取表数据
  fetchData(params: any) {
    return api.role.getAll(params);
  }

  // 新建
  handleCreate() {
    (this as any).$router.push({ name: "role-create" });
  }

  // 编辑
  handleEdit(index: number, row: RoleDto) {
    this.$router.push({
      name: "role-edit",
      params: { id: row.id!.toString() },
    });
  }

  handleGrantPermission(roleId: number) {
    this.$router.push({
      name: "role-grant-permissions",
      params: { id: roleId.toString() },
    });
  }

  viewRoleUsers(roleId: number) {
    this.$router.push({
      name: "RoleUsers",
      params: { id: roleId.toString() },
    });
  }

  // 删除
  async handleDelete(index: number, row: RoleDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.role
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }
}
